import axios from 'axios'
// import Vue from 'vue'
// import Vuex from 'vuex'
// import ElementUI from 'element-ui';

const request = axios.create()
// request.defaults.headers.common['Authorization'] =sessionStorage.getItem('token');

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers.common['Authorization'] =sessionStorage.getItem('token') || '';
    return config;
  }, function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error);
  });

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // console.log(response)
  // console.log(response)
    // if(response.data.code==500 || response.data.code==401){
    //  window.location.href='/login'
    //  sessionStorage.setItem('index',0)
    // }
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // ElementUI.Message({
    //   message: "未登录或无权限",
    //   type: 'error'
    // });
    // setTimeout(() => {
    //   window.location.href='/login'
    //   sessionStorage.setItem('index',0)
    // }, 1000);
    console.log(error)
    // 对响应错误做点什么
    // window.location.href='/login'
    // sessionStorage.setItem('index',0)
    // return Promise.reject(error);
  });

  export default request