import request from '../utils/request'
import { baseUrl } from '../utils/baseUrl'

export const Login=(data)=>request({//登录
    url:baseUrl+'/user/userInfo/loginByIdOrPhone',
    method:'post',
    data
})

export const loginNew=(data)=>request({//新的登录
    url:baseUrl+'/admin/account/login',
    method:'post',
    data
})


export const getOpinion=(data)=>request({//反馈列表
    url:baseUrl+'admin/feedback/pageList',
    method:'post',
    data
})

//订单列表
export const getorderList=(data)=>request({//订单列表
    url:baseUrl+'/custom/shop/order/pageList',
    method:'post',
    data
})

export const cancelOrder=(data)=>request({//取消订单
    url:baseUrl+'/admin/shop/order/cancel',
    method:'put',
    params:data
})

export const updateCourier=(data)=>request({//填写快递
    url:baseUrl+'admin/shop/order',
    method:'put',
    data
})

//门店管理
export const saveOrUpdate=(data)=>request({//门店管理保存或更新
    url:baseUrl+'/store/saveOrUpdate',
    method:'post',
    data
})

export const storepageList=(data)=>request({//门店分页获取
    url:baseUrl+'/store/pageList',
    method:'post',
    data
})


//学员分配
export const roleList=(data)=>request({//角色获取
    url:baseUrl+'/user/role/list',
    method:'get',
})

export const pageroleList=(data)=>request({//角色分页列表
    url:baseUrl+'/user/userInfo/pageList',
    method:'post',
    data
})

export const levelList=(data)=>request({//等级列表
    url:baseUrl+'/level/info/list',
    method:'get',
    params:data
})

export const beauticianList=(data)=>request({//美容师列表
    url:baseUrl+'/user/traineeInfo/pageShowToDirector',
    method:'post',
    data
})

export const homebeauticianList=(data)=>request({//首页美容师列表
    url:baseUrl+'/user/traineeInfo/pageWithStudyTime',
    method:'post',
    data
})

export const beauticianDetails=(data)=>request({//美容师详情
    url:baseUrl+'/user/userLevel/list',
    method:'get',
    params:data
})

export const levelList2=(data)=>request({//等级层级列表
    url:baseUrl+'/level/info/listInduceByStep',
    method:'get',
    params:data
})

export const usersaveOrUpdate=(data)=>request({//用户增加或更改
    url:baseUrl+'/user/userInfo/saveOrUpdate',
    method:'post',
    data
})

export const saveTeacher=(data)=>request({//讲师添加
    url:baseUrl+'/user/teacher/saveTeacher',
    method:'post',
    data
})

export const saveMentor=(data)=>request({//导师添加
    url:baseUrl+'/user/mentorInfo/saveMentor',
    method:'post',
    data
})

export const updateMentor=(data)=>request({//导师编辑
    url:baseUrl+'/user/mentorInfo/updateMentor',
    method:'post',
    data
})

export const saveTrainee=(data)=>request({//学员添加
    url:baseUrl+'/user/traineeInfo/saveTrainee',
    method:'post',
    data
})

export const updateTrainee=(data)=>request({//学员编辑
    url:baseUrl+'/user/traineeInfo/updateTrainee',
    method:'post',
    data
})

export const getTeacherList=(data)=>request({//导师列表
    url:baseUrl+'/user/mentorInfo/list',
    method:'get',
    params:data
})

//内容管理
// export const contentsaveOrUpdate=(data)=>request({//内容添加修改
//     url:baseUrl+'/content/contentInfo/saveOrUpdate',
//     method:'post',
//     data
// })
export const contentsaveOrAdd=(data)=>request({//内容添加
    url:baseUrl+'/content/contentInfo/save',
    method:'post',
    data
})
export const contentsaveOrUpdate=(data)=>request({//内容修改
    url:baseUrl+'/content/contentInfo/update',
    method:'post',
    data
})

export const contentsaveOrUpdate2=(data)=>request({//内容删除
    url:baseUrl+'/content/contentInfo/updateToNotValidity',
    method:'get',
    params:data
})

export const saveAndToCourse=(data)=>request({//保存并添加进课程
    url:baseUrl+'/content/contentInfo/saveAndToCourse',
    method:'post',
    data
})

export const courseInfoList=(data)=>request({//课程分页
    url:baseUrl+'/content/courseInfo/pageList',
    method:'post',
    data
})

export const coursesave=(data)=>request({//课程包保存
    url:baseUrl+'/content/courseInfo/save',
    method:'post',
    data
})
export const courseupdate=(data)=>request({//课程包更新
    url:baseUrl+'/content/courseInfo/update',
    method:'post',
    data
})

export const coursedelete=(data)=>request({//课程包删除
    url:baseUrl+'/content/courseInfo/deleteContents',
    method:'get',
    params:data
})

export const getmentorInfo=(data)=>request({//获取导师二维码
    url:baseUrl+'/user/mentorInfo/get',
    method:'get',
    params:data
})

export const contentpageList=(data)=>request({//内容分页获取
    url:baseUrl+'/content/contentInfo/pageList',
    method:'post',
    data
})

export const pageListNoUse=(data)=>request({//内容分页获取
    url:baseUrl+'/content/contentInfo/pageListNoUse',
    method:'post',
    data
})

export const pageListNoUse2=(data)=>request({//内容获取
    url:baseUrl+'/content/courseInfo/listContent',
    method:'get',
    params:data
})

export const updateContentsWeightMethod=(data)=>request({//更新内容权重
    url:baseUrl+'/content/courseInfo/updateContentsWeight',
    method:'post',
    data
})

export const addContents=(data)=>request({//添加多个内容到课程包
    url:baseUrl+'/content/courseInfo/addContents',
    method:'post',
    data
})

export const deleteContents=(data)=>request({//从课程包中移除多个内容
    url:baseUrl+'/content/courseInfo/deleteContents',
    method:'post',
    data
})

export const addPaper=(data)=>request({//添加调查问卷
    url:baseUrl+'/admin/survey',
    method:'post',
    data
})
export const delePaper=(data)=>request({//删除调查问卷
    url:baseUrl+'/admin/survey',
    method:'delete',
    params:data
})
export const delequestion=(data)=>request({//删除题目
    url:baseUrl+'admin/survey/question/delete',
    method:'get',
    params:data
})
export const updatePaper=(data)=>request({//更新调查问卷
    url:baseUrl+'admin/survey',
    method:'put',
    data
})

export const updatePaper3=(data)=>request({//更新选项以及标题
    url:baseUrl+'admin/survey/question/updateQuestionAndOptions',
    method:'post',
    data
})

export const PaperList=(data)=>request({//调查问卷列表
    url:baseUrl+'admin/survey/pageList',
    method:'post',
    data
})

export const answerlistUser=(data)=>request({//填空题回答列表
    url:baseUrl+'/admin/survey/question/answer/listUser',
    method:'post',
    data
})

export const questionlistSta=(data)=>request({//统计问题列表
    url:baseUrl+'/admin/survey/question/listSta',
    method:'get',
    params:data
})

export const surveyget=(data)=>request({//调查问卷详情
    url:baseUrl+'/admin/survey',
    method:'get',
    params:data
})


export const listNoSurveyList=(data)=>request({//课程包列表
    url:baseUrl+'/content/courseInfo/listNoSurvey',
    method:'get'
})
export const surveyquestionList=(data)=>request({//问题列表
    url:baseUrl+'admin/survey/question/listSta',
    method:'get',
    params:data
})

export const AddPaperList=(data)=>request({//添加试题
    url:baseUrl+'/admin/survey/question/saveList',
    method:'post',
    data
})

//考试管理
export const questiontypeList=(data)=>request({//试题类型获取
    url:baseUrl+'/question/question-type/list',
    method:'post',
    data
})

export const questionsaveOrUpdate=(data)=>request({//其他考题设置
    url:baseUrl+'/level/reviewInfo/saveOrUpdate',
    method:'post',
    data
})

export const questionList=(data)=>request({//其他考题列表
    url:baseUrl+'/level/reviewInfo/list',
    method:'post',
    data
})

export const questionupdateAllInfo=(data)=>request({//试卷设置
    url:baseUrl+'/level/info/updateLevelAllInfo',
    method:'post',
    data
})

export const questionupdateAllInfonew=(data)=>request({//试卷设置保存或更新(新)
    url:baseUrl+'/levelExam/saveOrUpdate',
    method:'post',
    data
})

export const questiongetAllInfoById=(data)=>request({//通过id获取试卷信息
    url:baseUrl+'/level/info/getLevelAllInfo',
    method:'get',
    params:data
})

export const questiongetAllInfoByIdnew=(data)=>request({//通过id获取试卷信息(新)
    url:baseUrl+'/levelExam/get',
    method:'get',
    params:data
})

//审核端
export const reviewUserList=(data)=>request({//审核列表
    url:baseUrl+'/review/reviewUser/pageList',
    method:'post',
    data
})

export const reviewUserDetails=(data)=>request({//审核详情
    url:baseUrl+'/review/reviewUser/getById',
    method:'get',
    params:data
})

export const reviewUserupdate=(data)=>request({//审核
    url:baseUrl+'/review/reviewUser/update',
    method:'post',
    data
})

//题库管理
// export const qssaveOrUpdate=(data)=>request({//添加试题
//     url:baseUrl+'/question/questionInfo/saveOrUpdate',
//     method:'post',
//     data
// })

export const saveGoodsClass=(data)=>request({//保存商品分类
    url:baseUrl+'admin/shop/goods/category/save',
    method:'post',
    data
})

export const updateGoodsClass=(data)=>request({//更新商品分类
    url:baseUrl+'admin/shop/goods/category/update',
    method:'put',
    data
})

export const deleGoodsClass=(data)=>request({//删除商品分类
    url:baseUrl+'admin/shop/goods/category/del',
    method:'delete',
    params:data
})

export const goodsClassList=(data)=>request({//分页商品分类列表
    url:baseUrl+'admin/shop/goods/category/pageList',
    method:'post',
    data
})

export const goodsClassListAll=(data)=>request({//全部商品分类列表
    url:baseUrl+'admin/shop/goods/category/list',
    method:'post',
    data
})

export const saveGoods=(data)=>request({//保存商品
    url:baseUrl+'admin/shop/goods',
    method:'post',
    data
})

export const deleGoods=(data)=>request({//删除商品
    url:baseUrl+'admin/shop/goods',
    method:'delete',
    params:data
})

export const updateStock=(data)=>request({//更新库存数量
    url:baseUrl+'admin/shop/goods/stock',
    method:'put',
    data
})

export const detailsGoods=(data)=>request({//商品详情
    url:baseUrl+'/admin/shop/goods',
    method:'get',
    params:data
})

export const updateGoods=(data)=>request({//更新商品
    url:baseUrl+'admin/shop/goods',
    method:'put',
    data
})

export const goodsList=(data)=>request({//商品列表
    url:baseUrl+'/admin/shop/goods/pageList',
    method:'post',
    data
})


export const qssaveOrUpdate=(data)=>request({//保存试题
    url:baseUrl+'admin/question',
    method:'post',
    data
})

export const qssavedel=(data)=>request({//删除试题
    url:baseUrl+'admin/question',
    method:'delete',
    params:data
})

export const qssaveOrUpdate2=(data)=>request({//更新试题
    url:baseUrl+'/admin/question',
    method:'put',
    data
})

export const qsList=(data)=>request({//分页获取题目数据
    url:baseUrl+'/admin/question/pageList',
    method:'post',
    data
})


//导师端
export const userExamList=(data)=>request({//用户试卷列表
    url:baseUrl+'/levelup/paperInfo/pageList',
    method:'post',
    data
})

export const questionExamList=(data)=>request({//获取试题
    url:baseUrl+'/levelup/userQuestion/pageList',
    method:'post',
    data
})

export const questionExamupdate=(data)=>request({//更新试题
    url:baseUrl+'/levelup/userQuestion/update',
    method:'post',
    data
})

export const userQuestiongetById=(data)=>request({//上下题
    url:baseUrl+'/levelup/userQuestion/getById',
    method:'get',
    params:data
})


export const endPaper=(data)=>request({//阅卷结束
    url:baseUrl+'/levelup/paperInfo/endPaper',
    method:'get',
    params:data
})

export const getCosCredit=(data)=>request({//获取上传校验凭证 content
    url:baseUrl+'/manage/cos/getCosCredit',
    method:'get'
})

export const getCosCreditUser=(data)=>request({//获取上传校验凭证 user
    url:baseUrl+'/cos/getCosCredit',
    method:'get'
})

export const getpageLevelUserList=(data)=>request({//获取等级用户
    url:baseUrl+'/user/userInfo/pageLevelUserList',
    method:'post',
    data
})

export const deleteByIdAndLevelId=(data)=>request({//删除其他考题设置
    url:baseUrl+'/level/reviewInfo/deleteByIdAndLevelId',
    method:'get',
    params:data
})

export const listByUserAndLevelId=(data)=>request({//获取图片视频列表
    url:baseUrl+'/review/reviewDetail/listByUserAndLevelId',
    method:'post',
    data
})

export const getNewestExamState=(data)=>request({//获取最新的考试状态
    url:baseUrl+'/levelup/customPaperInfo/getNewestExamState',
    method:'get',
    params:data
})

export const getAllQuestionAndStatistic=(data)=>request({//获取某张试卷所有题目统计信息
    url:baseUrl+'/levelup/paperInfo/getAllQuestionAndStatistic',
    method:'get',
    params:data
})

export const getStatistic=(data)=>request({//获取统计信息
    url:baseUrl+'/manage/statistic/getStatistic',
    method:'get',
    params:data
})

export const getRichText=(data)=>request({//获取富文本
    // url:'https://sihuankangyuan.cn/'+data,
    url:data,
    method:'get',
    
})

export const studentList=(data)=>request({//分页获取学员
    url:baseUrl+'/user/traineeWithMentor/pageTraineeList',
    method:'post',
    data
})

export const addMentor=(data)=>request({//分配导师
    url:baseUrl+'/user/traineeWithMentor/addMentor',
    method:'post',
    data
})

export const LevelInfosaveOrUpdate=(data)=>request({//保存更新考试须知考试规则
    url:baseUrl+'/level/info/saveOrUpdate',
    method:'post',
    data
})

// export const teacherList=(data)=>request({//获取讲师列表
//     url:baseUrl+'/user/teacher/list',
//     method:'post',
//     data
// })

export const treeList=(data)=>request({//根据角色获取权限树
    url:baseUrl+'admin/role/permission/tree',
    method:'get',
    params:data
})

export const treeList2=(data)=>request({//不根据角色获取权限树
    url:baseUrl+'/admin/permission/tree',
    method:'get',
    params:data
})

export const saveMenu=(data)=>request({//新增菜单
    url:baseUrl+'admin/permission',
    method:'post',
    data
})



export const updateMenu=(data)=>request({//更新菜单
    url:baseUrl+'admin/permission',
    method:'put',
    data
})

export const deletMenu=(data)=>request({//删除菜单
    url:baseUrl+'admin/permission',
    method:'delete',
    params:data
})

export const updateTree=(data)=>request({//修改权限树
    url:baseUrl+'admin/role/permission',
    method:'put',
    data
})

export const setUserVip=(data)=>request({//设成vip
    url:baseUrl+'/user/traineeInfo/updateTraineeToVip',
    method:'get',
    params:data
})

export const addCourseClass=(data)=>request({//新增课程分类
    url:baseUrl+'/admin/course/category',
    method:'post',
    data
})

export const updateCourseClass=(data)=>request({//修改课程分类
    url:baseUrl+'/admin/course/category',
    method:'put',
    data
})

export const courseClassList=(data)=>request({//课程分类列表
    url:baseUrl+'/admin/course/category/list',
    method:'get',
    params:data
})

export const currentCourseList=(data)=>request({//当前等级的课程列表
    url:baseUrl+'/courses/list',
    method:'get',
    params:data
})

export const addcontent=(data)=>request({//添加内容进课程包
    url:baseUrl+'/content',
    method:'post',
    data
})

export const updatecontent=(data)=>request({//修改内容
    url:baseUrl+'/content',
    method:'put',
    data
})

export const getcontent=(data)=>request({//内容列表
    url:baseUrl+'/contents',
    method:'get',
    params:data
})

export const getCountHome=(data)=>request({//会员数量趋势图
    url:baseUrl+'/admin/data/trainees/count/home',
    method:'get',
    params:data
})

export const courseDeleteAll=(data)=>request({//删除
    url:baseUrl+'/admin/course/category',
    method:'delete',
    params:data
})

export const contentDeleteAll=(data)=>request({//删除
    url:baseUrl+'/content',
    method:'delete',
    params:data
})

export const agentData=(data)=>request({//获取代理商数据总览
    url:baseUrl+'/admin/data/trainees/count',
    method:'get',
    params:data
})

export const agentData2=(data)=>request({//获取代理商数据总览5小时以上
    url:baseUrl+'/admin/data/trainees/count/study-time',
    method:'get',
    params:data
})

export const agentTrainees=(data)=>request({//获取代理商用户学习数据列表
    url:baseUrl+'/admin/data/trainees',
    method:'GET',
    params:data
})

export const agentTraineesPage=(data)=>request({//获取代理商用户学习数据列表（分页）
    url:baseUrl+'/admin/data/trainees/page',
    method:'get',
    params:data
})

export const updatefeedback=(data)=>request({//更进反馈
    url:baseUrl+'admin/feedback',
    method:'put',
    data
})

export const getLevelList=(data)=>request({//等级学习时长
    url:baseUrl+'/admin/data/trainee/time/level',
    method:'get',
    params:data
})

export const getcourselList=(data)=>request({//课程学习时长
    url:baseUrl+'admin/data/trainee/time/course',
    method:'get',
    params:data
})


export const traineeAdd=(data)=>request({//添加学员
    url:baseUrl+'admin/user/trainee',
    method:'post',
    data
})

export const traineeLook=(data)=>request({//查看学员
    url:baseUrl+'admin/user/trainee/get',
    method:'get',
    params:data
})

export const traineeUpdate=(data)=>request({//更新学员
    url:baseUrl+'/admin/user/trainee',
    method:'put',
    data
})

export const updateVipStatus=(data)=>request({//更新为vip
    url:baseUrl+'/admin/user/trainee/vip',
    method:'put',
    params:data
})

// export const updateVipFalse=(data)=>request({//更新为非vip
//     url:baseUrl+'/admin/user/trainee/notVip',
//     method:'get',
//     params:data
// })

export const traineeList=(data)=>request({//学员列表
    url:baseUrl+'/admin/user/trainee/pageList',
    method:'post',
    data
})


export const teacherList=(data)=>request({//讲师列表
    url:baseUrl+'/admin/user/teacher/list',
    method:'post',
    params:data
})

export const teacherAdd=(data)=>request({//添加讲师
    url:baseUrl+'admin/user/teacher',
    method:'post',
    data
})

export const teacherUpdate=(data)=>request({//更新讲师
    url:baseUrl+'admin/user/teacher',
    method:'put',
    data
})

export const agentAdd=(data)=>request({//添加代理商
    url:baseUrl+'admin/user/agent',
    method:'post',
    data
})

export const agentUpdate=(data)=>request({//更新代理商
    url:baseUrl+'admin/user/agent',
    method:'put',
    data
})

export const agentList=(data)=>request({//代理商列表
    url:baseUrl+'/admin/user/agent/list',
    method:'get',
    params:data
})

export const courseAdd=(data)=>request({//添加课程包
    url:baseUrl+'/admin/course',
    method:'post',
    data
})

export const courseEdit=(data)=>request({//更新课程包
    url:baseUrl+'/admin/course',
    method:'put',
    data
})

export const courseDele=(data)=>request({//删除课程包
    url:baseUrl+'/admin/course',
    method:'delete',
    params:data
})

export const courseList=(data)=>request({//课程包列表
    url:baseUrl+'/admin/course/list',
    method:'get',
    params:data
})

export const contentAdd=(data)=>request({//添加内容
    url:baseUrl+'/admin/content',
    method:'post',
    data
})

export const contentList=(data)=>request({//内容列表
    url:baseUrl+'/admin/content/list',
    method:'get',
    params:data
})

export const contentDelet=(data)=>request({//删除内容
    url:baseUrl+'/admin/content',
    method:'delete',
    params:data
})

export const contentEdit=(data)=>request({//更新内容
    url:baseUrl+'/admin/content',
    method:'put',
    data
})

export const pushList=(data)=>request({//推荐列表
    url:baseUrl+'/admin/home/item',
    method:'get',
    params:data
})

export const pushAdd=(data)=>request({//添加推荐
    url:baseUrl+'/admin/home/item',
    method:'post',
    data
})

export const pushEdit=(data)=>request({//更新推荐
    url:baseUrl+'/admin/home/item',
    method:'put',
    data
})

export const pushDelet=(data)=>request({//删除推荐
    url:baseUrl+'/admin/home/item',
    method:'delete',
    params:data
})

export const agentDes=(data)=>request({//代理商详情
    url:baseUrl+'/admin/user/agent',
    method:'get',
    params:data
})

export const tokenChange=(data)=>request({//刷新token
    url:baseUrl+'/admin/tokenChange',
    method:'get',
    params:data
})

export const uploadSignature=(data)=>request({//获取云点播上传凭证
    url:baseUrl+'/manage/cloud-demon/getUploadSignature',
    method:'get',
})

export const dellmedia=(data)=>request({//视频删除
    url:baseUrl+'/manage/cloud-demon/mediaDetail',
    method:'delete',
    params:data
})

export const getAgentVip=(data)=>request({//代理商vip数量
    url:baseUrl+'/admin/data/trainees/count/buy-vip',
    method:'get',
    params:data
})

export const getUserData=(data)=>request({//查看代理商账号密码
    url:baseUrl+'/admin/account',
    method:'get',
    params:data
})

export const assistantGetUserData=(data)=>request({//查看代理商账号密码
    url:baseUrl+'/admin/data/trainees/count/studyData',
    method:'get',
    params:data
})

export const buyVipDataMethod=(data)=>request({//查看购买vip数据
    url:baseUrl+'/admin/data/trainees/data/buy-vip',
    method:'get',
    params:data
})

export const renewPaylist=(data)=>request({//续费列表
    url:baseUrl+'virtual-goods/vips',
    method:'get',
    params:data
})

export const stateRenewPay=(data,method)=>request({//添加/修改续费
    url:baseUrl+'virtual-goods/vip',
    method:method,
    data
})

export const deleteRenewPay=(data)=>request({//删除
    url:baseUrl+'virtual-goods/vip',
    method:'DELETE',
    params:data
})

export const getIndexData=(data)=>request({//首页看板各项数据
    url:baseUrl+'/admin/data/trainees/data/home',
    method:'get',
    params:data
})

export const getTagApi=(data)=>request({//获取身份列表
    url:baseUrl+'/admin/tags',
    method:'get',
    params:data
})

export const operationTagApi=(data,method)=>request({//身份列表 增删改
    url:baseUrl+'/admin/tag',
    method,
    data
})

export const countTagApi=(data)=>request({//身份列表 发布数量
    url:baseUrl+'/admin/tags/count',
    method:'get',
    params:data
})

export const getWarehouseApi=(data)=>request({//获取仓库列表
    url:baseUrl+'/admin/material/warehouse',
    method:'get',
    params:data
})

export const saveWarehouseApi=(data,method)=>request({//保存、修改仓库
    url:baseUrl+'/admin/material/warehouse',
    method,
    data
})

export const deleteWarehouseApi=(data)=>request({//删除仓库
    url:baseUrl+'/admin/material/warehouse',
    method:'delete',
    params:data
})

export const getContentApi=(data)=>request({//获取内容
    url:baseUrl+'/admin/material',
    method:'get',
    params:data
})

export const materialInfoApi=(data,method)=>request({//保存、修改、删除内容
    url:baseUrl+'admin/material/info',
    method,
    data
})

export const materialResources=(data,method)=>request({//删除资源
    url:baseUrl+'admin/material/resources',
    method,
    data
})

export const materialResource=(data,method)=>request({//修改，保存资源
    url:baseUrl+'admin/material/resource',
    method,
    data
})
export const weChatMiniProgramPublish=(data)=>request({//视频发布
    url:baseUrl+'manage/cloud-demon/weChatMiniProgramPublish',
    method:'get',
    params:data
})

export const getVideoContents=(data)=>request({//获取音频列表
    url:baseUrl+'admin/audio/contents',
    method:'get',
    params:data
})


export const audioResource=(data,method)=>request({//修改，保存音频内容
    url:baseUrl+'admin/audio/content',
    method,
    data
})

export const deleteVideoContent=(data)=>request({//删除音频
    url:baseUrl+'admin/audio/content',
    method:'DELETE',
    params:data
})


export const moveContentApi=(data)=>request({//移动内容
    url:baseUrl+'admin/material/info',
    method:'put',
    data
})


export const buysList=(data)=>request({//查询复购以及新购买
    url:baseUrl+'/admin/user/trainee/pageList',
    method:'post',
    data
})

export const saveOrUpdatesection=(data)=>request({//添加或者编辑分段/知识点
    url:baseUrl+'course/content/text',
    method:'post',
    data
})

export const sectionListMethod=(data)=>request({//分段/知识点列表
    url:baseUrl+'course/content/text/list',
    method:'get',
    params:data
})

export const getAiList=(data)=>request({//用户ai问题
    url:'http://sihuankangyuan.cn:8080/api/v815/train/user-chat-record/pageList',
    method:'post',
    data
})